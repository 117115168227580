<template>
  <div class="wrapper w-100">
    <!-- START BOT -->
    <div v-if="isCultureBotVisible" class="culture_chat_container">
      <!-- passer la liste des questions du tunel en parametre de AiChat -->
      <AiChat @hideComponent="() => {
          switchDisplayedCulture('main');
          isCultureBotVisible = false;
        }
        " @culture-generated="onCultureGenerated" @error="errorHandler"/>
      <div id="rightSide" body-class="text-center">
        <!-- TODO: mettre ! devant generatedculture lorsque le container de culture setra fonctionel -->
        <b-row v-if="Object.keys(generatedCulture).length < 3" class="tree-card vh-100">
          <b-col class="tree-card not-selected">
            La culture générée s'affichera ici
          </b-col>
        </b-row>
        <div v-else id="rightSideContainer">
          <b-alert show>
            Ci-dessous votre fiche de culture pour une superficie de 1Ha. Ces
            informations proviennent de source variées et peuvent etre ajustées.
          </b-alert>
          <GeneratedCultureContainer :culture="generatedCulture" />
          <button-spinner id="save_geneatedCulture_button" :disabled="generatingCulture" variant="primary"
            @click="saveGeneratedCulture" :fetching="generatingCulture">
            Enregistrer la culture
          </button-spinner>
        </div>
      </div>
    </div>
    <!-- END BOT -->
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-tabs card ref="unValidatedCultureSection">
            <!-- CULTURE DETAILS CONTAINER -->
            <b-card no-body class="p-0 mb-0 mt-4 mx-4">
              <b-tab @click.capture="switchDisplayedCulture('main')" title="Cultures" class="p-0" active></b-tab>
              <b-tab @click="switchDisplayedCulture(4)" title="Validées" class="p-0"></b-tab>
              <b-tab @click="switchDisplayedCulture(3)" title="En cours de validation"
                class="p-0"></b-tab>
              <b-tab @click="switchDisplayedCulture(5)" title="Non validées" class="p-0"></b-tab>
              <b-row v-if="treeData.length < 1" class="tree-card">
                <b-col class="tree-card not-selected">
                  Aucune donnée
                </b-col>
              </b-row>
              <b-row v-else class="tree-card">
                <b-col cols="4" class="tree-part pr-0">
                  <c-input class="pr-4" container-class="" placeholder="Saisissez pour filtrer"
                    v-model="cultureFilter"></c-input>
                  <div v-if="fetchingAllCultures" class="text-center">
                    <b-spinner></b-spinner><br />
                    Chargement...
                  </div>
                  <template v-else>
                    <!-- culture ou etape de culture -->
                    <tree :data="treeData" :filter="cultureFilter" ref="cultureTree" @node:selected="handleNodeSelection"
                      :options="treeOptions" id="tree_container">
                      <div slot-scope="{ node }" class="node-info node_block">
                        <template v-if="node.data.code == 'CULTURE'">
                          <div>
                            <i class="fa fa-leaf mr-2"></i>
                            <span class="node-name">{{ node.text }}</span>
                            <svg v-if="[2, 3, 4, 5].includes(node.data.isOwn)" class="ai_badge ml-2"
                              :class="'ai_badge_' + node.data.isOwn" version="1.0" xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 245.000000 216.000000" preserveAspectRatio="xMidYMid meet">
                              <g transform="translate(0.000000,216.000000) scale(0.100000,-0.100000)" stroke="none">
                                <path
                                  d="M1566 1634 c-15 -41 -33 -77 -39 -79 -7 -2 -45 -14 -84 -25 l-73 -22 0 -43 0 -42 85 -23 c67 -18 86 -27 91 -44 3 -11 15 -47 27 -78 l22 -58 46 0 47 0 28 78 28 79 88 23 88 24 0 41 0 41 -87 25 -86 25 -26 65 c-36 88 -37 89 -84 89 l-42 0 -29 -76z" />
                                <path
                                  d="M795 1528 c-28 -87 -285 -969 -285 -979 0 -5 38 -9 83 -9 l84 0 34 118 34 117 192 3 c223 3 196 18 238 -135 l28 -103 84 0 c57 0 83 4 83 12 0 6 -54 196 -121 422 -67 226 -131 445 -143 486 l-21 75 -143 3 c-109 2 -143 0 -147 -10z m168 -160 c2 -13 16 -61 30 -108 57 -192 97 -330 97 -335 0 -3 -68 -5 -151 -5 -117 0 -150 3 -147 13 3 6 34 112 69 234 50 173 68 223 80 223 9 0 19 -10 22 -22z" />
                                <path d="M1560 810 l0 -270 80 0 80 0 0 270 0 270 -80 0 -80 0 0 -270z" />
                              </g>
                            </svg>
                          </div>
                          <div>
                            <MenuItem v-if="[0].includes(node.data.isOwn)" :options="['import']"
                              :exploitationId="exploitationId" :culture="node" :cultureSteps="allCultureData.find(
                                culture => culture.id == node.data.id
                              ).cultureSteps
                                " @operation-error="errorHandler" @not-permit-error="handleNotPermitError" />
                            <MenuItem v-if="[4].includes(node.data.isOwn)" :options="['import', 'delete']"
                              :exploitationId="exploitationId" :culture="node" :cultureSteps="allCultureData.find(
                                culture => culture.id == node.data.id
                              ).cultureSteps
                                " @delete-success="handleCultureDeletion" @operation-error="errorHandler"
                              @not-permit-error="handleNotPermitError" />
                            <MenuItem v-if="[5].includes(node.data.isOwn)" :options="['delete']"
                              :exploitationId="exploitationId" :culture="node" @delete-success="handleCultureDeletion"
                              @operation-error="errorHandler" @not-permit-error="handleNotPermitError" />
                          </div>
                        </template>
                        <template v-else><!-- le noeud est une etape de culture -->
                          <div>
                            <i class="fa fa-leaf mr-2"></i>
                            <span class="node-name">{{ node.text }}</span>
                          </div>
                          <div></div>
                        </template>
                      </div>
                    </tree><!-- fin culture ou etape de culture -->
                  </template>
                </b-col>

                <b-col v-if="columnActivityModeDisabled" cols="8" class="not-selected">{{ columnActiviyMessage }}</b-col>
                <template v-else>
                  <b-col cols="4">
                    <template>
                      <p class="p-2">
                        <other-title>Etape de culture:</other-title>{{ currentStep.text }}
                      </p>
                      <tree :data="treeDataActivities" ref="tree" @node:selected="handleNodeSelection">
                        <div slot-scope="{ node }" class="node-info node_block activity_list">
                          <span class="node-name">{{ node.text }}</span>
                        </div>
                      </tree>
                    </template>
                  </b-col>
                  <b-col cols="4" class="tree-card-input">
                    <template v-if="fetchingActivityInformations">
                      <p>
                        <other-title>Activité:</other-title>{{ currentActivity.text }}
                      </p>
                      <p class="default-unit">
                        Surface considérée :<default-unit>1 ha</default-unit>
                      </p>
                      <c-input container-class="mb-2" type="quantity" label="Durée de l'activité" placeholder="Ex: 12"
                        v-model="activityDataDuration" unit="Jour(s)" disabled></c-input>
                      <c-input container-class="mb-2" type="quantity" label="Coût total de l'activité"
                        placeholder="Ex: 50000" v-model="activityDataCost" :unit="exploitationCurrency"
                        disabled></c-input>
                      <c-input container-class="mb-2" type="quantity" label="Nombre de personnes réquis"
                        placeholder="Ex: 50000" v-model="activityDataPersonNumber" unit="Personne(s)" disabled></c-input>
                      <template v-if="activityDataInputs.length != 0">
                        <label class="text-muted font-weight-bold">Intrants</label>
                        <b-input-group class="mb-3" v-for="input in activityDataInputs">
                          <b-input-group-prepend class="w-50">
                            <b-input-group-text class="w-100">{{
                              input.name
                            }}</b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input type="number" class="form-control" placeholder="Qté" v-model="input.quantity"
                            min="0" step="1" aria-disabled />
                          <b-input-group-append class="unit">
                            <b-input-group-text class="w-100">{{
                              input.unit
                            }}</b-input-group-text>
                          </b-input-group-append>
                        </b-input-group>
                      </template>
                      <template v-if="activityDataEquipments.length != 0">
                        <label class="text-muted font-weight-bold">Equipements</label>
                        <b-input-group class="mb-3" v-for="equipment in activityDataEquipments">
                          <b-input-group-prepend class="w-50">
                            <b-input-group-text class="w-100">{{
                              equipment.name
                            }}</b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input type="number" class="form-control" placeholder="Qté" v-model="equipment.quantity"
                            min="0" step="1" />
                        </b-input-group>
                      </template>
                    </template>
                    <p v-else class="not-selected">
                      Aucune activité n'a été selectionnée
                    </p>
                  </b-col>
                </template>
              </b-row> </b-card><!-- END CULTURE DETAIL CONTAINER -->
            <div class="mx-4 my-2 d-flex position-relative justify-content-end">
              <div id="OpenChatButton" @click="togleCultureBotVisibility" class="btn btn-primary">
                <span>Assistant</span><svg data-v-2113471a="" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  fill="currentColor" viewBox="0 0 16 16" class="bi bi-send-fill">
                  <path data-v-2113471a=""
                    d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z">
                  </path>
                </svg>
              </div>
            </div>
          </b-tabs>
        </b-col>
      </b-row>

      <!-- UPDATE CULTURE SECTION -->
      <b-row class="mb-3">
        <!-- formulaire de modification d'une culture -->

        <!-- <b-modal ref="modify-culture-modal">
          <template #modal-title><i class="fa fa-edit mr-2"></i> Modifiez les étapes de votre culture de {{
            activatedCulture }}</template>
          <div class="p-0">
            <b-col v-if="cultureFormDisplay" cols="12" class="border border-5 border-primary">
              <b-form>
                <c-input type="image" v-model="picture" default="DEFAULT_CULTURE_PICTURE"></c-input>
                <c-input label="Nom de la culture" placeholder="Ex: Cacao" v-model="name" container-class="mb-3 mt-3"
                  :state="nameState"></c-input>
                <c-input label="Commentaire" placeholder="Entrez un commentaire..." v-model="comment"
                  container-class="mb-4" type="textarea"></c-input>
              </b-form>
              <b-row class="mt-4">
                <b-col cols="12">
                  <div v-if="fetchingStepList" class="text-center">
                    <b-spinner></b-spinner><br>Chargement...
                  </div>
                  <ul class="p-0" id="cultures_steps_container">
                    <div>
                      <li v-for="item in stepCulturesList" :key="item.id"
                        class="list-group-item list-group-item-action hover-highlight">
                        {{ item.name }}
                        <b-link @click.stop="removeStepCulture(item)"><i
                            class="fa fa-remove text-danger ml-1"></i></b-link>
                      </li>
                    </div>
                    <div id="add-culture-container">
                      <button-spinner v-if="cultureFormDisplay && stepAddBtnDisplay" variant="primary" type="submit"
                        @click="stepAddBtnDisplay = false">
                        Ajouter une étape<i class="fa fa-plus ml-1"></i>
                      </button-spinner>
                      <div v-if="cultureFormDisplay && !stepAddBtnDisplay">
                        <ul class="list-group">
                          <li v-for="item in stepCultureAutocompleteData" @click="selectCulture(item)" :key="item.id"
                            class="list-group-item list-group-item-action hover-highlight">
                            <i class="fa fa-plus mr-2"></i>{{ item.name }}
                          </li>
                        </ul>
                        <li @click="newStepModal = true; stepName = ''; stepComment = ''"
                          class="list-group-item list-group-item-success mb-3 hover-highlight">
                          <i class="fa fa-plus-circle mr-2"></i>Creer une nouvelle etape
                        </li>
                        <button-spinner v-if="cultureFormDisplay" variant="success" type="submit" class="px-4 mt-1 mb-3"
                          @click="stepAddBtnDisplay = true">
                          modification terminée
                        </button-spinner>
                      </div>
                    </div>
                  </ul>
                </b-col>

              </b-row>
            </b-col>
          </div>
          <template #modal-footer>
            <div class="col-12 d-flex justify-content-end p-0">
              <button-spinner variant="success" type="submit" class="px-4" @click="updateCulture"
                :fetching="fetchingNewCulture">
                Enregistrer<i class="fa fa-save ml-2"></i>
              </button-spinner>
            </div>
          </template>
        </b-modal> -->
        <!-- END FORMULAIRE DE MODIFICATION D'UNE CULTURE -->

        <!-- <b-col v-if="stepFormDisplay" cols="4" offset="1" class="border border-5 border-primary">
          <div>
            <p class="mt-3"><b><i class="fa fa-edit mr-2"></i>Modifiez les activités de l'étape {{ stepName }} si
                nécéssaire.</b></p>
          </div>
          <div>
            <b-form>
              <c-input container-class="mb-3 mt-3" label="Nom de l'étape" placeholder="Ex: Préparation du sol"
                v-model="stepName" :state="stepNameState">
                Veuillez saisir un nom
              </c-input>
              <c-input container-class="mb-3" type="textarea" label="Commentaire" placeholder="Entrez un commentaire..."
                v-model="stepComment">
              </c-input>
            </b-form>
          </div>
          <div>
            <b-row>
              <b-col cols="5">


                <ul class="list-group">
                  <li v-for="item in activitiesList" :key="item.id"
                    class="list-group-item list-group-item-action hover-highlight" @click="onSelectActivity(item)">

                    {{ item.name }}
                    <b-link @click.prevent="removeActivity(item)">
                      <i class="fa fa-remove text-danger"></i>
                    </b-link>

                  </li>
                </ul>
                <div class="w-100 text-left mt-1 d-flex justify-content-center" v-if="activityAddBtnDisplay">
                  <button-spinner variant="success" type="submit" class="px-4 mt-3" @click="updatePiaStep"
                    :fetching="fetchingNewStep" v-if="stepFormDisplay">
                    Enregistrer<i class="fa fa-save ml-2"></i>
                  </button-spinner><button-spinner variant="primary" type="submit" class="px-4 mt-3 ml-3"
                    @click="activityAddBtnDisplay = false" v-if="stepFormDisplay">
                    <i class="fa fa-plus"></i>
                  </button-spinner>
                </div>
              </b-col>
              <b-col cols="5" offset="1" v-if="activitiesList.length && !activityAddBtnDisplay">

                <ul class="list-group">
                  <li v-for="item in activityAutocompleteData" :key="item.id"
                    class="list-group-item list-group-item-action hover-highlight" @click="selectActivity(item)">

                    <i class="fa fa-plus mr-2"></i>

                    {{ item.name }}

                  </li>
                </ul>

                <li class="list-group-item list-group-item-success mb-3 hover-highlight" @click="newActivityModal = true">
                  <i class="fa fa-plus-circle mr-2"></i>Creer une nouvelle Activité
                </li>
                <button-spinner variant="success" type="submit" class="px-4 mt-1 mb-3"
                  @click="activityAddBtnDisplay = true" v-if="cultureFormDisplay">
                  modification terminée
                </button-spinner>

              </b-col>
            </b-row>
            <b-row>
              <b-col cols="5">
                <div>
                  <button-spinner variant="danger" type="delete" class="px-4 mb-5 mt-5 ml-2" @click="deleteStep"
                    v-if="stepFormDisplay">Supprimer l'etape <i class="fa fa-trash ml-1"></i></button-spinner>
                </div>
              </b-col>
            </b-row>


          </div>

        </b-col> -->

        <!--  <b-col v-if="activityImportFormDisplay" cols="2" offset="1" class="border border-5 border-primary">
          <div>
            <p class="mt-3"><b><i class="fa fa-edit mr-2"></i>veuillez modifier les paramètres de l'activité
                {{ activityName }} si nécéssaires</b></p>
          </div>
          <div>
            <b-form>

              <c-input container-class="mb-3 mt-3" label="Nom de l'activité" placeholder="Ex: Abattage"
                v-model="activityName" :state="activityNameState">
                Veuillez saisir un nom
              </c-input>
              <p>
                Surface considérée :
                <default-unit>1 ha</default-unit>
              </p>
              <c-input container-class="mb-2" type="quantity" label="Durée de l'activité" placeholder="Ex: 30"
                v-model="activityDuration" unit="Jour(s)">
              </c-input>
              <c-input container-class="mb-2" type="quantity" label="Coût total de l'activité" placeholder="Ex: 50000"
                v-model="activityCost" :unit="exploitationCurrency + ' / pers. / jour'">
              </c-input>
              <c-input container-class="mb-3 mt-3" label="Nombre personnes de l'activité" placeholder="Ex: 50"
                v-model="activityPersonNumber">
              </c-input>

              <b-input-group v-if="equipments.length">
                <b-badge variant="secondary" pill class="m-1 py-1" v-for="equipment in equipments">
                  {{ equipment.name }} - {{ equipment.quantity }}
                  <b-link @click.prevent="removeEquipment(equipment)">
                    <i class="fa fa-remove text-danger"></i>
                  </b-link>
                </b-badge>
              </b-input-group>
              <p class="text-center" v-else>Aucun matériel.</p>

              <b-input-group class="mb-3 mt-3">
                <b-autocomplete-input placeholder="Ajouter un matériel" v-model="equipment"
                  :data="equipmentAutocompleteData" class="autocomplete" @hit="addEquipment($event)"
                  ref="equipmentAutocompleteInput" :serializer="item => item.name">
                  <template slot="append">
                    <b-form-input type="number" class="form-control input-number-add" placeholder="Qté"
                      v-model="quantityEquipment" min="0" step="1" :state="quantityStateAdd" />
                    <b-button variant="primary" @click.prevent="addEquipment()">
                      Ajouter
                    </b-button>
                  </template>
                </b-autocomplete-input>
              </b-input-group>

              <b-input-group v-if="inputs.length">
                <b-badge variant="secondary" pill class="m-1 py-1" v-for="input in inputs">
                  {{ input.name }} - {{ input.quantity }}
                  <b-link @click.prevent="removeInput(input)">
                    <i class="fa fa-remove text-danger"></i>
                  </b-link>
                </b-badge>
              </b-input-group>
              <p class="text-center" v-else>Aucun intrant.</p>

              <b-input-group class="mb-3 mt-3">
                <b-autocomplete-input placeholder="Ajouter un intrant" v-model="input" :data="inputAutocompleteData"
                  class="autocomplete" @hit="addInput($event)" ref="inputAutocompleteInput"
                  :serializer="item => item.name">
                  <template slot="append">
                    <b-form-input type="number" class="form-control input-number-add" placeholder="Qté"
                      v-model="quantityInput" min="0" step="1" :state="quantityStateAdd" />
                    <b-button variant="primary" @click.prevent="addInput()">
                      Ajouter
                    </b-button>
                  </template>
                </b-autocomplete-input>
              </b-input-group>

              <c-input container-class="mb-3" type="textarea" label="Commentaire" placeholder="Entrez un commentaire..."
                v-model="activityComment">
              </c-input>
            </b-form>


            <button-spinner variant="success" type="submit" class="px-4 mb-3" @click="updatePiaActivity"
              :fetching="fetchingNewActivity">
              Enregistrer<i class="fa fa-save ml-1"></i>
            </button-spinner>
          </div>

          <b-row>
            <b-col cols="10">
              <div>
                <button-spinner variant="danger" type="delete" class="px-4 mb-5 mt-5 ml-2" @click="deleteActivity"
                  v-if="activityImportFormDisplay">Supprimer l'activité <i class="fa fa-trash ml-1"></i></button-spinner>
              </div>
            </b-col>
          </b-row>



        </b-col> --> </b-row><!-- END UPDATE CULTURE SECTION -->

      <!-- UPDATE ACTIVITIES SECTION -->
      <b-modal :title="activityModalTitle" class="modal-primary" v-model="newActivityModal" @hide="onActivityModalClose">
        <b-form>
          <p class="text-danger" v-if="hasError">{{ errorMessage }}</p>

          <c-input container-class="mb-3 mt-3" label="Nom de l'activité" placeholder="Ex: Abattage" v-model="activityName"
            :state="activityNameState">
            Veuillez saisir un nom
          </c-input>
          <p>
            Surface considérée :
            <default-unit>1 ha</default-unit>
          </p>
          <c-input container-class="mb-2" type="quantity" label="Durée de l'activité" placeholder="Ex: 30"
            v-model="activityDuration" unit="Jour(s)">
          </c-input>
          <c-input container-class="mb-2" type="quantity" label="Coût total de l'activité" placeholder="Ex: 50000"
            v-model="activityCost" :unit="exploitationCurrency + ' / pers. / jour'">
          </c-input>
          <c-input container-class="mb-3 mt-3" label="Nombre personnes de l'activité" placeholder="Ex: 50"
            v-model="activityPersonNumber">
          </c-input>

          <b-input-group v-if="equipments.length">
            <b-badge variant="secondary" pill class="m-1 py-1" v-for="equipment in equipments">
              {{ equipment.name }} - {{ equipment.quantity }}
              <b-link @click.prevent="removeEquipment(equipment)">
                <i class="fa fa-remove text-danger"></i>
              </b-link>
            </b-badge>
          </b-input-group>
          <p class="text-center" v-else>Aucun matériel.</p>

          <b-input-group class="mb-3 mt-3">
            <b-autocomplete-input placeholder="Ajouter un matériel" v-model="equipment" :data="equipmentAutocompleteData"
              class="autocomplete" @hit="addEquipment($event)" ref="equipmentAutocompleteInput"
              :serializer="item => item.name">
              <template slot="append">
                <b-form-input type="number" class="form-control input-number-add" placeholder="Qté"
                  v-model="quantityEquipment" min="0" step="1" :state="quantityStateAdd" />
                <b-button variant="primary" @click.prevent="addEquipment()">
                  Ajouter
                </b-button>
              </template>
            </b-autocomplete-input>
          </b-input-group>

          <b-input-group v-if="inputs.length">
            <b-badge variant="secondary" pill class="m-1 py-1" v-for="input in inputs">
              {{ input.name }} - {{ input.quantity }}
              <b-link @click.prevent="removeInput(input)">
                <i class="fa fa-remove text-danger"></i>
              </b-link>
            </b-badge>
          </b-input-group>
          <p class="text-center" v-else>Aucun intrant.</p>

          <b-input-group class="mb-3 mt-3">
            <b-autocomplete-input placeholder="Ajouter un intrant" v-model="input" :data="inputAutocompleteData"
              class="autocomplete" @hit="addInput($event)" ref="inputAutocompleteInput" :serializer="item => item.name">
              <template slot="append">
                <b-form-input type="number" class="form-control input-number-add" placeholder="Qté"
                  v-model="quantityInput" min="0" step="1" :state="quantityStateAdd" />
                <b-button variant="primary" @click.prevent="addInput()">
                  Ajouter
                </b-button>
              </template>
            </b-autocomplete-input>
          </b-input-group>

          <c-input container-class="mb-3" type="textarea" label="Commentaire" placeholder="Entrez un commentaire..."
            v-model="activityComment">
          </c-input>
        </b-form>

        <div slot="modal-footer" class="w-100 text-center">
          <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitActivity"
            :fetching="fetchingNewActivity">
            {{ submitActivityBtnText }}
          </button-spinner>
          <b-button variant="secondary" @click="newActivityModal = false" class="ml-2">
            Annuler
          </b-button>
        </div>
      </b-modal><!-- END UPDATE ACTIVITIES SECTION -->

      <!-- UPDATE STEPS SECTION -->
      <b-modal :title="stepModalTitle" class="modal-primary" v-model="newStepModal" @hide="onStepModalClose">
        <b-form>
          <p class="text-danger" v-if="hasError">{{ errorMessage }}</p>

          <c-input container-class="mb-3 mt-3" label="Nom de l'étape" placeholder="Ex: Préparation du sol"
            v-model="stepName" :state="stepNameState">
            Veuillez saisir un nom
          </c-input>
          <!-- <b-input-group v-if="activitiesList.length">
                        <b-badge variant="secondary" pill class="m-1 py-1" v-for="activity in activitiesList">
                          {{activity.name}}
                          <b-link @click.prevent="removeActivity(activity)">
                            <i class="fa fa-remove text-danger"></i>
                          </b-link>
                        </b-badge>
                      </b-input-group>
                      <p class="text-center" v-else>Aucune activité.</p> -->

          <b-input-group class="mb-3 mt-3">
            <b-autocomplete-input placeholder="Ajouter une activité" v-model="activity" :data="activityAutocompleteData"
              class="autocomplete" @hit="addActivity($event)" ref="activityAutocompleteInput"
              :serializer="item => item.name">
              <template slot="append">
                <b-button variant="primary" @click.prevent="addActivity()">
                  Ajouter
                </b-button>
              </template>
            </b-autocomplete-input>
          </b-input-group>

          <b-row class="row justify-content-left">
            <b-col cols="6">
              <ul class="list-group">
                <li v-for="item in activityAutocompleteData" :key="item.id"
                  class="list-group-item list-group-item-action hover-highlight" @click="selectActivity(item)">
                  <i class="fa fa-plus mr-2"></i>

                  {{ item.name }}
                </li>
              </ul>

              <li class="list-group-item list-group-item-success mb-3 hover-highlight" @click="newActivityModal = true">
                <i class="fa fa-plus-circle mr-2"></i>Creer une nouvelle
                Activité
              </li>
            </b-col>

            <b-col cols="6">
              <p class="text-center" v-if="!activitiesList.length">
                Aucune activité selectionnée.
              </p>

              <ul class="list-group">
                <li v-for="item in activitiesList" :key="item.id"
                  class="list-group-item list-group-item-action hover-highlight">
                  {{ item.name }}
                  <b-link @click.prevent="removeActivity(item)">
                    <i class="fa fa-remove text-danger"></i>
                  </b-link>
                </li>
              </ul>
            </b-col>
          </b-row>

          <c-input container-class="mb-3" type="textarea" label="Commentaire" placeholder="Entrez un commentaire..."
            v-model="stepComment">
          </c-input>

          <b-modal :title="activityModalTitle" class="modal-primary" v-model="newActivityModal"
            @hide="onActivityModalClose">
            <b-form>
              <p class="text-danger" v-if="hasError">{{ errorMessage }}</p>

              <c-input container-class="mb-3 mt-3" label="Nom de l'activité" placeholder="Ex: Abattage"
                v-model="activityName" :state="activityNameState">
                Veuillez saisir un nom
              </c-input>
              <p>
                Surface considérée :
                <default-unit>1 ha</default-unit>
              </p>
              <c-input container-class="mb-2" type="quantity" label="Durée de l'activité" placeholder="Ex: 30"
                v-model="activityDuration" unit="Jour(s)">
              </c-input>
              <c-input container-class="mb-2" type="quantity" label="Coût total de l'activité" placeholder="Ex: 50000"
                v-model="activityCost" :unit="exploitationCurrency + ' / pers. / jour'">
              </c-input>
              <c-input container-class="mb-3 mt-3" label="Nombre personnes de l'activité" placeholder="Ex: 50"
                v-model="activityPersonNumber">
              </c-input>

              <b-input-group v-if="equipments.length">
                <b-badge variant="secondary" pill class="m-1 py-1" v-for="equipment in equipments">
                  {{ equipment.name }} - {{ equipment.quantity }}
                  <b-link @click.prevent="removeEquipment(equipment)">
                    <i class="fa fa-remove text-danger"></i>
                  </b-link>
                </b-badge>
              </b-input-group>
              <p class="text-center" v-else>Aucun matériel.</p>

              <b-input-group class="mb-3 mt-3">
                <b-autocomplete-input placeholder="Ajouter un matériel" v-model="equipment"
                  :data="equipmentAutocompleteData" class="autocomplete" @hit="addEquipment($event)"
                  ref="equipmentAutocompleteInput" :serializer="item => item.name">
                  <template slot="append">
                    <b-form-input type="number" class="form-control input-number-add" placeholder="Qté"
                      v-model="quantityEquipment" min="0" step="1" :state="quantityStateAdd" />
                    <b-button variant="primary" @click.prevent="addEquipment()">
                      Ajouter
                    </b-button>
                  </template>
                </b-autocomplete-input>
              </b-input-group>

              <b-input-group v-if="inputs.length">
                <b-badge variant="secondary" pill class="m-1 py-1" v-for="input in inputs">
                  {{ input.name }} - {{ input.quantity }}
                  <b-link @click.prevent="removeInput(input)">
                    <i class="fa fa-remove text-danger"></i>
                  </b-link>
                </b-badge>
              </b-input-group>
              <p class="text-center" v-else>Aucun intrant.</p>

              <b-input-group class="mb-3 mt-3">
                <b-autocomplete-input placeholder="Ajouter un intrant" v-model="input" :data="inputAutocompleteData"
                  class="autocomplete" @hit="addInput($event)" ref="inputAutocompleteInput"
                  :serializer="item => item.name">
                  <template slot="append">
                    <b-form-input type="number" class="form-control input-number-add" placeholder="Qté"
                      v-model="quantityInput" min="0" step="1" :state="quantityStateAdd" />
                    <b-button variant="primary" @click.prevent="addInput()">
                      Ajouter
                    </b-button>
                  </template>
                </b-autocomplete-input>
              </b-input-group>

              <c-input container-class="mb-3" type="textarea" label="Commentaire" placeholder="Entrez un commentaire..."
                v-model="activityComment">
              </c-input>
            </b-form>

            <div slot="modal-footer" class="w-100 text-center">
              <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitActivity"
                :fetching="fetchingNewActivity">
                {{ submitActivityBtnText }}
              </button-spinner>
              <b-button variant="secondary" @click="newActivityModal = false" class="ml-2">
                Annuler
              </b-button>
            </div>
          </b-modal>
        </b-form>

        <div slot="modal-footer" class="w-100 text-center">
          <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitStep" :fetching="fetchingNewStep">
            creer
          </button-spinner>
          <b-button variant="secondary" @click="newStepModal = false" class="ml-2">
            Annuler
          </b-button>
        </div>
      </b-modal><!-- END UPDATE CULTURE SECTION -->
    </div>
  </div>
</template>

<script>
import { INSPECT_MAX_BYTES } from "pdfmake/build/pdfmake";
import {
  Api,
  Regex,
  Toast,
  asset,
  env,
  IO,
  String,
  price
} from "../../../helpers";
import AiChat from "../../chatBot/AiChat.vue";
import MenuItem from "./MenuItem.vue";
import GeneratedCultureContainer from "./GeneratedCultureContainer.vue";
export default {
  name: "ExploitationPiaCultures",
  title: "PIA - Cultures",
  components: {
    AiChat,
    MenuItem,
    GeneratedCultureContainer
  },

  data() {
    return {
      generatingCulture: false,
      //TODO: supprimer tout ce qui est en rapport avec formDisplay
      displayedCultureIsOwn: "main", // définie les types de cultures actuellement affichées
      errorOccur: false,
      stepDeleteBtnDisplay: true,
      activityDeleteBtnDisplay: true,
      activityExploitationId: "",
      stepExploitationId: "",
      id_step: "",
      activityImportFormDisplay: false,
      cultureFormDisplay: false,
      stepFormDisplay: false,
      cultureImportMsgDisplay: false,
      stepImportMsgDisplay: false,
      activityImportMsgDisplay: false,
      stepAddBtnDisplay: true,
      activityAddBtnDisplay: true,
      stepscult: [],
      activatedCulture: "",
      activatedStepCulture: "",
      cultures: [],
      fields: [
        { key: "picture", label: "Aperçu", asset: this.asset },
        { key: "name", label: "Nom", sortable: true },
        { key: "comment", label: "Commentaire" },
        { key: "actions", label: "Actions" }
      ],
      actions: [
        {
          type: "primary",
          icon: "icon-note",
          title: "Modifier",
          handler: this.editCulture
        },
        {
          type: "danger",
          icon: "icon-trash",
          title: "Supprimer",
          handler: this.deleteCulture
        }
      ],
      picture: null,
      name: "",
      comment: "",
      newCultureModal: false,
      fetchingNewCulture: false,
      fetchingCultureList: false,
      selectedCulture: null,

      submitted: false,
      error: null,

      steps: [],
      stepsFields: [
        { key: "name", label: "Nom", sortable: true },
        { key: "comment", label: "Commentaire" },
        { key: "actions", label: "Actions" }
      ],
      stepsActions: [
        {
          type: "primary",
          icon: "icon-note",
          title: "Modifier",
          handler: this.editStep
        },
        {
          type: "danger",
          icon: "icon-trash",
          title: "Supprimer",
          handler: this.deleteStep
        }
      ],
      stepName: "",
      stepComment: "",
      newStepModal: false,
      fetchingNewStep: false,
      fetchingStepList: false,
      selectedStep: null,

      activities: [],
      activitiesFields: [
        { key: "name", label: "Nom", sortable: true },
        { key: "_equipments", label: "Matériel" },
        { key: "_inputs", label: "Intrants" },
        // {key: 'comment', label: 'Commentaire'},
        { key: "actions", label: "Actions" }
      ],
      activitiesActions: [
        {
          type: "primary",
          icon: "icon-note",
          title: "Modifier",
          handler: this.editActivity
        },
        {
          type: "danger",
          icon: "icon-trash",
          title: "Supprimer",
          handler: this.deleteActivity
        }
      ],

      activityName: "",
      activityDuration: "",
      activityCost: "",
      activityPersonNumber: "",
      activityComment: "",
      newActivityModal: false,
      fetchingNewActivity: false,
      fetchingActivityList: false,
      selectedNodeData: null,
      equipments: [],
      equipment: "",
      quantityEquipment: "",
      quantityInput: "",
      equipmentAutocompleteData: [],
      fetchingEquipmentList: false,
      inputs: [],
      input: "",
      inputAutocompleteData: [],
      fetchingInputList: false,

      cultureIndex: 0,
      currentCulture: null,
      currentStep: "",
      currentActivity: null,
      fetchingAllCultures: false,
      fetchingActivityInformations: false,
      cultureFilter: "",
      treeOptions: {
        filter: {
          emptyText: "Aucune culture ne correspond à votre recherche",
          showChildren: true
        }
      },
      allCultureData: [],
      /* TODO: replace true by false when the culture bot wil be functional */
      isCultureBotVisible: false,
      /* todo: remplacer par un objet vide */
      generatedCulture: {},
      treeData: [
        {
          text: "Bonjour",
          data: {
            created: true
          },
          children: [
            {
              text: "hello"
            }
          ]
        }
      ],
      treeDataActivities: [],
      treeDataStepsCultures: [],
      activityDataInputs: [
        {
          id: 0,
          name: "engrais",
          quantity: 12,
          unit: "KCl"
        }
      ],
      activityDataEquipments: [
        {
          id: 0,
          name: "Machette",
          quantity: 12
        },
        {
          id: 1,
          name: "Houe",
          quantity: 23
        }
      ],
      activityDataDuration: 15,
      activityDataCost: 15,
      activityDataPersonNumber: 15,
      columnActivityModeDisabled: true,
      columnActiviyMessage:
        "Selectionner une etape de culture pour pouvoir visualiser le message",

      stepCulturesList: [],
      stepCultureAutocompleteData: [],
      step_culture: "",
      activity: "",
      activityAutocompleteData: [],
      activitiesList: [],
      submitAddEquipement: false,

      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Quittez",
          buttonPrevious: "Précédent",
          buttonNext: "Suivant",
          buttonStop: "Terminé"
        }
      }
    };
  },

  created() {
    this.fetchingCultureList = true;
    Api.get("/exploitation/culture/list", {
      exploitationId: this.exploitationId
    })
      .then(res => {
        if (res.data.status === "success" && res.data.data) {
          this.cultures = res.data.data.filter(item => item.isOwn);
        } else {
          this.error = res.data.error;
        }
      })
      .catch(error => {
        this.error = {
          message: "Echec de la connexion au serveur"
        };
      })
      .then(() => {
        this.fetchingCultureList = false;
      });

    this.fetchingStepList = true;
    Api.get("/exploitation/culture/step/all", {
      exploitationId: this.exploitationId
    })
      .then(res => {
        if (res.data.status === "success" && res.data.data) {
          this.steps = res.data.data.filter(item => item.isOwn);
          this.stepCultureAutocompleteData = res.data.data;
        } else {
          this.error = res.data.error;
        }
      })
      .catch(error => {
        this.error = {
          message: "Echec de la connexion au serveur"
        };
      })
      .then(() => {
        this.fetchingStepList = false;
      });

    this.fetchingActivityList = true;
    Api.get("/exploitation/activity/all", {
      exploitationId: this.exploitationId
    })
      .then(res => {
        if (res.data.status === "success" && res.data.data) {
          this.activities = res.data.data.filter(item => item.isOwn);
          this.activityAutocompleteData = res.data.data;
        } else {
          this.error = res.data.error;
        }
      })
      .catch(error => {
        this.error = {
          message: "Echec de la connexion au serveur"
        };
      })
      .then(() => {
        this.fetchingActivityList = false;
      });

    this.fetchingEquipmentList = true;
    Api.get("/exploitation/equipment/all", {
      exploitationId: this.exploitationId
    })
      .then(res => {
        if (res.data.status === "success" && res.data.data) {
          this.equipmentAutocompleteData = res.data.data;
        } else {
          this.error = res.data.error;
        }
      })
      .catch(error => {
        this.error = {
          message: "Echec de la connexion au serveur"
        };
      })
      .then(() => {
        this.fetchingEquipmentList = false;
      });

    this.fetchingInputList = true;
    Api.get("/exploitation/input/all", {
      exploitationId: this.exploitationId
    })
      .then(res => {
        if (res.data.status === "success" && res.data.data) {
          this.inputAutocompleteData = res.data.data;
        } else {
          this.error = res.data.error;
        }
      })
      .catch(error => {
        this.error = {
          message: "Echec de la connexion au serveur"
        };
      })
      .then(() => {
        this.fetchingInputList = false;
      });

    this.fetchingAllCultures = true;
    Api.get("/exploitation/culture/resume", {
      exploitationId: this.exploitationId
    })
      .then(res => {
        if (res.data.status === "success" && res.data.data) {
          this.allCultureData = res.data.data.sort((a, b) => {
            if (a.isOWn) return -1;
            else if (b.isOwn) return 1;
            else return 0;
          });
        } else {
          this.error = res.data.error;
        }
      })
      .catch(error => {
        this.error = {
          message: "Echec de la connexion au serveur"
        };
      })
      .then(() => {
        this.fetchingAllCultures = false;
      });
  },

  mounted() { 
  },

  watch: {
    error(e) {
      if (e) Toast.error(e);
    },
    allCultureData() {
      let displayedCulture = this.treeData[0].data.isOwn;
      //main represente les cultures du système
      this.switchDisplayedCulture(displayedCulture ? displayedCulture : "main");
    }
  },

  computed: {
    exploitationId() {
      return this.$store.getters.exploitationId;
    },
    rootNodes() {
      return this.treeData.filter(node => !node.parentId);
    }, // Return the root nodes
    hasError() {
      return !!this.error;
    },
    errorMessage() {
      return this.error ? this.error.message : null;
    },
    _picture() {
      return this.picture ? this.picture : env("DEFAULT_CULTURE_PICTURE");
    },
    nameState() {
      return !this.submitted || this.name.trim().length >= 3 ? null : false;
    },
    submitBtnText() {
      return "Importer";
    },
    modalTitle() {
      return this.selectedCulture ? "Modifier la culture" : "Nouvelle culture";
    },
    stepNameState() {
      return !this.submitted || this.stepName.trim().length >= 3 ? null : false;
    },
    submitStepBtnText() {
      return "Importer l'étape";
    },
    stepModalTitle() {
      return this.selectedStep
        ? "Modifier l'étape"
        : "Nouvelle étape de culture";
    },
    activityNameState() {
      return !this.submitted || this.activityName.trim().length >= 3
        ? null
        : false;
    },
    submitActivityBtnText() {
      return this.selectedNodeData ? "Enregistrer" : "Créer";
    },
    activityModalTitle() {
      return this.selectedNodeData
        ? "Modifier l'activité"
        : "Nouvelle activité";
    },
    _activities() {
      return this.activities.map(a => ({
        ...a,
        _inputs: a.inputs.map(i => i.name).join(", "),
        _equipments: a.equipments.map(i => i.name).join(", ")
      }));
    },
    quantityStateAdd() {
      return (
        this.submitAddEquipement || typeof this.quantityEquipment == "number"
      );
    },
    exploitationCurrency() {
      return price();
    }
  },

  methods: {
    handleCultureDeletion(cultureId) {
      this.cultures = this.cultures.filter(
        (item, index, array) => item.id != cultureId
      );
      this.allCultureData = this.allCultureData.filter(
        item => item.id != cultureId
      );
      Toast.success("Culture supprimée avec succès !");
    },
    errorHandler(errorMessage) {
      this.error = { message: errorMessage };
    },
    handleNotPermitError(notPermitErrorMessage) {
      this.error = notPermitErrorMessage;
    },

    onCultureGenerated(culture) {
      this.generatedCulture = {
        ...culture,
        exploitationId: this.exploitationId,
        isOwn: 3
      };
    },

    saveGeneratedCulture() {
      this.generatingCulture = true;
      Api.post("/exploitation/culture/save", this.generatedCulture)
      .then(
        res => {
          if (res.data.status === "success") {
            this.isCultureBotVisible = false;

            let cultureDTO = {
              id: this.allCultureData.length + 1,
              exploitation_id: this.cultures[0].exploitation_id,
              unit: null,
              name: this.generatedCulture.name,
              comment: this.generatedCulture.comment,
              picture: "",
              isOwn: 3,
              createdAt: new Date(),
              updatedAt: new Date()
            };
            let allCultureDTO = {
              ...cultureDTO,
              cultureSteps: this.generatedCulture.cultureSteps
            };
            this.cultures.push(cultureDTO);
            this.allCultureData.push(allCultureDTO);
            //TODO essayer sans le switch
            this.switchDisplayedCulture("3");

            //mise en évidence de la tab des cultures non validées en cliwuant dessus
            this.$refs.unValidatedCultureSection.$el.querySelector('.card-header ul li:nth-child(3) a').click();

            this.generatedCulture = {};
            Toast.success(
              "Culture enregistrée ! Disponible après validation par un admin"
            );
          } else {
            Toast.error(
              "Une erreur est survenue lors de l'enregistrement de la culture"
            );
          }
        }
      ).catch (e =>{
        Toast.error(
          "Problème de connexion veuillez réessayer plus tard !"
        );
      })
      .then(()=>{
        this.generatingCulture = false
      })
    },

    delay(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    },

    async executeSequentially() {
      this.stepCulturesList = this.stepCulturesList.filter(
        item => item.id !== this.id_step
      );
      await this.delay(2000);
      await this.newStep();
      await this.delay(2000);
      await this.updateCulture();
    },

    async executeSequentially2() {
      this.activitiesList = this.activitiesList.filter(
        item => item.id !== this.currentActivity.id
      );
      await this.delay(2000);
      await this.newActivity();
      await this.delay(2000);
      await this.updatePiaStep();
    },

    updatePiaActivity() {
      if (this.activityExploitationId) {
        this.executeSequentially2().then(() => {
          this.stepFormDisplay = false;
          this.cultureFormDisplay = false;
          this.activityImportFormDisplay = false;
        });
      } else {
        this.updateActivity();
        // this.stepFormDisplay=false
        // this.cultureFormDisplay= false
        // this.activityImportFormDisplay = false
      }
    },

    asset(path) {
      return asset(path, env("DEFAULT_CULTURE_PICTURE"));
    },

    updatePiaStep() {
      if (this.stepExploitationId) {
        this.executeSequentially().then(() => {
          this.stepFormDisplay = false;
          this.cultureFormDisplay = false;
        });
      } else {
        this.updateStep();
        // this.stepFormDisplay=false
        // this.cultureFormDisplay= false
      }
    },

    selectCulture(item) {
      if (!this.stepCulturesList.find(element => element.id === item.id))
        this.stepCulturesList.push(item);
      else alert("Etape déja ajoutée!");
    },
    togleCultureBotVisibility() {
      this.isCultureBotVisible = !this.isCultureBotVisible;
    },
    switchDisplayedCulture(isOwn) {
      console.log("=========> entrée dans switchdisplayedculture");
      /* 0 : pour culture generique
         1 : appartenenat à l'utilisateur
         2 : en cours de création assistée
         3 : enregistrée et non modérée
         4 : acceptée
         5 : refusée
      */
      let filteredData;
      if (isOwn == "main") {
        filteredData = this.allCultureData.filter(item => !item.isOwn);
      } else {
        filteredData = this.allCultureData.filter(item => item.isOwn == isOwn);
      }
      this.treeData = filteredData.map(item => {
        let result = {
          text: item.name,
          data: {
            name: item.name,
            comment: item.comment,
            picture: item.picture,

            isOwn: item.isOwn,
            id: item.id,
            code: "CULTURE"
          },
          children: item.cultureSteps.map(step => ({
            text: step.name,
            data: {
              name: step.name,
              comment: step.comment,
              isOwn: step.isOwn,
              id: step.id,
              id_exploitation: step.exploitation_id,
              isHarvest: step.isHarvest,
              code: "STEP_CULTURE",
              childrenLength: step.activities.length
            }
          }))
        };
        return result;
      });
      try {
        this.$refs["cultureTree"].setModel(this.treeData);
        this.columnActivityModeDisabled = true;
        this.fetchingActivityInformations = false;
      } catch (e) { }
    },

    onSelectStep(item) {
      this.selectedStep = item;
      this.activityImportFormDisplay = false;
      this.stepName = item.name;
      this.stepComment = item.comment;
      this.activitiesList = item.activities;
      this.id_step = item.id;
      this.stepExploitationId = this.stepExploitationId = isNaN(
        parseInt({ toString: () => this.id_exploitation })
      );
    },

    onSelectActivity(item) {
      this.activityImportFormDisplay = true;
      this.activityName = item.name;
      this.activityComment = item.comment;
      this.activityDuration = item.duration;
      this.activityCost = item.cost;
      this.activityPersonNumber = item.personNumber;
      this.inputs = item.inputs;
      this.equipments = item.equipments;
    },

    selectActivity(item) {
      // if (!this.activitiesList.includes(item))
      //   this.activitiesList.push(item)
      // else alert('Activité déja ajoutée!')

      if (!this.activitiesList.find(element => element.id === item.id))
        this.activitiesList.push(item);
      else alert("Activité déja ajoutée!");
    },

    switchTab(tabIndex) {
      this.activeTab = tabIndex;
    },

    onPictureChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const reader = new FileReader();
      const vm = this;

      reader.onload = e => {
        vm.picture = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    },
    valid() {
      return this.name.trim().length >= 3;
    },
    onModalClose() {
      this.submitted = false;
      this.error = null;
      this.fetchingNewCulture = false;
      this.picture = null;
      this.name = "";
      this.comment = "";
      this.selectedCulture = null;
      this.stepCulturesList = [];
    },

    newCulture() {
      this.submitted = true;
      if (!this.valid()) return;

      this.fetchingNewCulture = true;
      Api.post("/exploitation/culture/create", {
        exploitationId: this.exploitationId,
        name: this.name,
        comment: this.comment,
        picture: this.picture,
        cultureSteps: this.stepCulturesList.map(item => item.id)
      })
        .then(res => {
          if (res.data.status === "success" && res.data.data) {
            this.error = null;
            this.newCultureModal = false;
            this.cultures.push(res.data.data);
            this.allCultureData.push(res.data.data);

            this.$router.push(
              `/exploitation/${this.exploitationId}/cultures/ownlist`
            );
            if (this.cultureImportMsgDisplay)
              Toast.success("culture importée avec succès !");
            else Toast.success("culture créée avec succès !");

            this.cultureImportMsgDisplay = false;
          } else {
            this.error = res.data.error;
          }
        })
        .catch(error => {
          this.error = {
            message: "Echec de la connexion au serveur"
          };
        })
        .then(() => {
          this.fetchingNewCulture = false;
        });
    },

    updateCulture() {
      this.submitted = true;
      if (!this.valid()) return;

      this.fetchingNewCulture = true;
      Api.post("/exploitation/culture/update", {
        exploitationId: this.exploitationId,
        //cultureId: this.selectedCulture.id,
        cultureId: this.id_culture,
        name: this.name,
        comment: this.comment,
        picture: Regex.isNullOrURL(this.picture) ? null : this.picture,
        cultureSteps: this.stepCulturesList.map(item => item.id)
      })
        .then(res => {
          if (res.data.status === "success" && res.data.data) {
            this.error = null;
            this.newCultureModal = false;
            this.cultures = this.cultures.filter(
              (item, index, array) => item.id !== this.id_culture
            );
            this.cultures.push(res.data.data);
            this.allCultureData = this.allCultureData.map(item =>
              item.id === res.data.data.id ? res.data.data : item
            );

            Toast.success("Culture mise à jour avec succès !");
          } else {
            this.error = res.data.error;
          }
        })
        .catch(error => {
          this.error = {
            message: "Echec de la connexion au serveur"
          };
        })
        .then(() => {
          this.fetchingNewCulture = false;
          this.$refs["modify-culture-modal"].hide();
        });
    },

    onSubmit() {
      this.selectedCulture ? this.updateCulture() : this.newCulture();
    },

    onCultureImport() {
      this.cultureImportMsgDisplay = true;
      this.onSubmit();
    },

    onStepImport() {
      this.stepImportMsgDisplay = true;
      this.onSubmitStep();
    },

    editCulture(culture) {
      this.selectedCulture = culture;
      this.picture = asset(culture.picture);
      this.name = culture.name;
      this.comment = culture.comment;
      this.newCultureModal = true;
      this.stepCulturesList = this.allCultureData.find(
        item => item.id == culture.id
      ).cultureSteps;
    },

    onExport() {
      IO.exportDataTable(this.cultures, "cultures", {
        title: "Exploitation: " + this.$store.getters.exploitationName,
        name: "Liste des cultures",
        headers: [
          { title: "Aperçu", key: "picture", width: "auto", asset: this.asset },
          { title: "Nom", key: "name", width: "auto" },
          { title: "Commentaire", key: "comment", width: "*" }
        ]
      });
    },

    validStep() {
      return this.stepName.trim().length >= 3;
    },

    onStepModalClose() {
      this.submitted = false;
      this.error = null;
      this.fetchingNewStep = false;
      this.stepName = "";
      this.stepComment = "";
      this.selectedStep = null;
      this.activitiesList = [];
    },

    newStep() {
      this.submitted = true;
      if (!this.validStep()) return;

      this.fetchingNewStep = true;
      Api.post("/exploitation/culture/step/create", {
        exploitationId: this.exploitationId,
        name: this.stepName,
        comment: this.stepComment,
        activities: this.activitiesList.map(item => item.id)
      })
        .then(res => {
          if (res.data.status === "success" && res.data.data) {
            this.error = null;
            this.newStepModal = false;
            this.steps.push(res.data.data);
            this.stepCultureAutocompleteData.push({
              ...res.data.data,
              activities: this.activitiesList
            });
            this.stepCulturesList.push({
              ...res.data.data,
              activities: this.activitiesList
            });
            if (this.stepImportMsgDisplay)
              Toast.success("Etape de culture importée avec succès !");
            else Toast.success("Etape de culture créée avec succès !");

            this.stepImportMsgDisplay = false;
          } else {
            this.error = res.data.error;
          }
        })
        .catch(error => {
          this.error = {
            message: "Echec de la connexion au serveur"
          };
        })
        .then(() => {
          this.fetchingNewStep = false;
        });
    },

    updateStep() {
      this.submitted = true;
      if (!this.validStep()) return;

      this.fetchingNewStep = true;
      Api.post("/exploitation/culture/step/update", {
        exploitationId: this.exploitationId,
        cultureStepId: this.id_step,
        name: this.stepName,
        comment: this.stepComment,
        activities: this.activitiesList.map(item => item.id)
      })
        .then(res => {
          if (res.data.status === "success" && res.data.data) {
            this.error = null;
            this.newStepModal = false;
            this.steps = this.steps.filter(
              (item, index, array) => item.id !== this.id_step
            );
            this.steps.push(res.data.data);
            this.stepCultureAutocompleteData = this.stepCultureAutocompleteData.map(
              item =>
                item.id == res.data.data.id
                  ? {
                    ...res.data.data,
                    activities: this.activitiesList
                  }
                  : item
            );
            let newIndex = -1;
            this.allCultureData.map(item => {
              if (item.cultureSteps.find(el => el.id == this.id_step))
                newIndex = 1;
            });
            if (newIndex == 1) this.callResume();

            Toast.success("Etape de culture mise à jour avec succès !");
          } else {
            this.error = res.data.error;
          }
        })
        .catch(error => {
          this.error = {
            message: "Echec de la connexion au serveur"
          };
        })
        .then(() => {
          this.fetchingNewStep = false;
        });
    },

    onSubmitStep() {
      this.stepImportMsgDisplay = true;
      this.newStep();
      this.activitiesList = [];
    },

    editStep(step) {
      this.selectedStep = step;
      this.stepName = step.name;
      this.stepComment = step.comment;
      this.newStepModal = true;
      this.activitiesList = this.stepCultureAutocompleteData.find(
        item => item.id == step.id
      ).activities;
    },

    deleteStep() {
      const res = confirm(
        `Etes-vous sûr de vouloir supprimer l'étape de culture "${this.activatedStepCulture}" ?`
      );
      if (res) {
        Api.post("/exploitation/culture/step/delete", {
          exploitationId: this.exploitationId,
          cultureStepId: this.id_step
        })
          .then(res => {
            if (res.data.status === "success" && res.data.data) {
              this.steps = this.steps.filter(
                (item, index, array) => item.id !== this.id_step
              );
              Toast.success("Etape de culture supprimée avec succès !");
              this.allCultureData = this.allCultureData.map(item => ({
                ...item,
                cultureSteps: item.cultureSteps.filter(
                  el => el.id != this.id_step
                )
              }));
            } else {
              this.error =
                "vous n,êtes pas autorisé à supprimer une étape de culture proposée par le système PIA";
            }
          })
          .catch(error => {
            this.error = {
              message: "Echec de la connexion au serveur"
            };
          })
          .then(() => {
            // this.fetchingNewCulture = false
          });
      }
    },

    onExportSteps() {
      IO.exportDataTable(this.steps, "culture-steps", {
        title: "Exploitation: " + this.$store.getters.exploitationName,
        name: "Liste des étapes de culture",
        headers: [
          { title: "Nom", key: "name", width: "auto" },
          { title: "Commentaire", key: "comment", width: "*" }
        ]
      });
    },

    validActivity() {
      return this.activityName.trim().length >= 3;
    },

    onActivityModalClose() {
      this.submitted = false;
      this.error = null;
      this.fetchingNewActivity = false;
      this.activityName = "";
      this.activityComment = "";
      this.selectedActivity = null;
      this.equipments = [];
      this.clearEquipmentAutocompleteInput();
      this.inputs = [];
      this.clearInputAutocompleteInput();
    },

    newActivity() {
      this.submitted = true;
      if (!this.validActivity()) return;

      this.fetchingNewActivity = true;
      Api.post("/exploitation/activity/create", {
        exploitationId: this.exploitationId,
        name: this.activityName,
        comment: this.activityComment,
        duration: this.activityDuration,
        cost: this.activityCost,
        personNumber: this.activityPersonNumber,
        equipments: this.equipments.map(item => ({
          id: item.id,
          quantity: item.quantity
        })),
        inputs: this.inputs.map(item => ({
          id: item.id,
          quantity: item.quantity
        }))
      })
        .then(res => {
          if (res.data.status === "success" && res.data.data) {
            this.error = null;
            this.newActivityModal = false;
            this.activities.push(res.data.data);
            this.activityAutocompleteData.push(res.data.data);
            this.activitiesList.push(res.data.data);

            if (this.activityImportMsgDisplay)
              Toast.success("activité importée avec succès !");
            else Toast.success("activité créée avec succès !");
            this.activityImportFormDisplay = false;
          } else {
            this.error = res.data.error;
            this.activityImportFormDisplay = true;
          }
        })
        .catch(error => {
          this.error = {
            message: "Echec de la connexion au serveur"
          };
        })
        .then(() => {
          this.fetchingNewActivity = false;
        });
    },

    updateActivity() {
      this.submitted = true;
      if (!this.validActivity()) return;

      this.fetchingNewActivity = true;
      Api.post("/exploitation/activity/update", {
        exploitationId: this.exploitationId,
        activityId: this.currentActivity.id,
        name: this.activityName,
        comment: this.activityComment,
        duration: this.activityDuration,
        cost: this.activityCost,
        personNumber: this.activityPersonNumber,
        equipments: this.equipments.map(item => ({
          id: item.id,
          quantity: item.quantity
        })),
        inputs: this.inputs.map(item => ({
          id: item.id,
          quantity: item.quantity
        }))
      })
        .then(res => {
          if (res.data.status === "success" && res.data.data) {
            this.error = null;
            this.newActivityModal = false;
            this.activities = this.activities.filter(
              (item, index, array) => item.id !== this.currentActivity.id
            );
            this.activities.push(res.data.data);
            Toast.success("Activité mise à jour avec succès !");
            this.allCultureData = this.allCultureData.map(item => ({
              ...item,
              cultureSteps: item.cultureSteps.map(el => ({
                ...el,
                activities: el.activities.map(ac =>
                  ac.id == res.data.data.id ? res.data.data : ac
                )
              }))
            }));
          } else {
            this.error = res.data.error;
          }
        })
        .catch(error => {
          this.error = {
            message: "Echec de la connexion au serveur"
          };
        })
        .then(() => {
          this.fetchingNewActivity = false;
        });
    },

    onSubmitActivity() {
      this.activityImportMsgDisplay = true;
      this.newActivity();
      this.activityImportFormDisplay = false;
    },

    editActivity(activity) {
      this.selectedNodeData = activity;
      this.activityName = activity.name;
      this.activityComment = activity.comment;
      this.activityDuration = activity.duration;
      this.activityCost = activity.cost;
      this.activityPersonNumber = activity.personNumber;
      this.inputs = activity.inputs;
      this.equipments = activity.equipments;
      this.newActivityModal = true;
    },

    deleteActivity() {
      const res = confirm(
        `Etes-vous sûr de vouloir supprimer l'activité "${this.currentActivity.name}" ?`
      );
      if (res) {
        Api.post("/exploitation/activity/delete", {
          exploitationId: this.exploitationId,
          activityId: this.currentActivity.id
        })
          .then(res => {
            if (res.data.status === "success" && res.data.data) {
              this.activities = this.activities.filter(
                (item, index, array) => item.id !== this.currentActivity.id
              );
              Toast.success("Activité supprimée avec succès !");
              this.activityImportFormDisplay = false;
              this.stepFormDisplay = false;
              this.allCultureData = this.allCultureData.map(item => ({
                ...item,
                cultureSteps: item.cultureSteps.map(el => ({
                  ...el,
                  activities: el.activities.filter(
                    ac => ac.id != this.currentActivity.id
                  )
                }))
              }));
            } else {
              this.error =
                "vous n'êtes pas autorisée à supprimer une activité de culture proposée par le système";
            }
          })
          .catch(error => {
            this.error = {
              message: "Echec de la connexion au serveur"
            };
          })
          .then(() => {
            // this.fetchingNewCulture = false
          });
      }
    },

    onExportActivities() {
      IO.exportDataTable(this.steps, "activities", {
        title: "Exploitation: " + this.$store.getters.exploitationName,
        name: "Liste des activités",
        headers: [
          { title: "Nom", key: "name", width: "auto" },
          { title: "Commentaire", key: "comment", width: "*" }
        ]
      });
    },

    clearEquipmentAutocompleteInput() {
      this.$refs.equipmentAutocompleteInput.inputValue = "";
      this.equipment = "";
    },

    clearStepCultureAutocompleteInput() {
      this.$refs.stepCultureAutocompleteInput.inputValue = "";
      this.step_culture = "";
    },

    clearActivityAutocompleteInput() {
      this.$refs.activityAutocompleteInput.inputValue = "";
      this.activity = "";
    },

    removeEquipment(equipment) {
      this.equipments = this.equipments.filter(e => e.id !== equipment.id);
    },

    removeStepCulture(step_culture) {
      this.activitiesList = [];
      this.stepCulturesList = this.stepCulturesList.filter(
        e => e.id !== step_culture.id
      );
    },

    removeActivity(activity) {
      this.activitiesList = this.activitiesList.filter(
        e => e.id !== activity.id
      );
    },

    addEquipment(equipment) {
      this.submitAddEquipement = true;
      if (this.validAddEquipment(this.quantityEquipment)) {
        return;
      }
      if (!equipment && this.equipment.trim() !== "")
        equipment = this.equipmentAutocompleteData.find(e =>
          e.name.toLowerCase().includes(this.equipment.toLowerCase())
        );

      if (equipment) {
        if (!this.equipments.includes(equipment))
          this.equipments.push({
            ...equipment,
            quantity: this.quantityEquipment
          });
        this.quantityEquipment = "";
      } else alert("Aucun matériel trouvé !");

      this.submitAddEquipement = false;
      this.clearEquipmentAutocompleteInput();
    },

    validAddEquipment(val) {
      return typeof val == "number" && val >= 0;
    },
    addStepCulture(step_culture) {
      if (!step_culture && this.step_culture.trim() !== "")
        step_culture = this.stepCultureAutocompleteData.find(e =>
          e.name.toLowerCase().includes(this.step_culture.toLowerCase())
        );

      if (step_culture) {
        if (!this.stepCulturesList.includes(step_culture))
          this.stepCulturesList.push(step_culture);
      } else alert("Aucune étape trouvée !");

      this.clearStepCultureAutocompleteInput();
    },

    addActivity(actitivy) {
      if (!actitivy && this.actitivy.trim() !== "")
        actitivy = this.activityAutocompleteData.find(e =>
          e.name.toLowerCase().includes(this.actitivy.toLowerCase())
        );

      if (actitivy) {
        if (!this.activitiesList.includes(actitivy))
          this.activitiesList.push(actitivy);
      } else alert("Aucune actvité trouvée !");

      this.clearActivityAutocompleteInput();
    },

    clearInputAutocompleteInput() {
      this.$refs.inputAutocompleteInput.inputValue = "";
      this.input = "";
    },

    removeInput(input) {
      this.inputs = this.inputs.filter(e => e.id !== input.id);
    },

    addInput(input) {
      if (!input && this.input.trim() !== "")
        input = this.inputAutocompleteData.find(i =>
          i.name.toLowerCase().includes(this.input.toLowerCase())
        );

      if (input) {
        if (!this.inputs.includes(input))
          this.inputs.push({
            ...input,
            quantity: this.quantityInput
          });
      } else alert("Aucun intrant trouvé !");

      this.clearInputAutocompleteInput();
    },
    togleCultureUpdateForm(culture) {
      //l'evenement updating-culture envoie automatiquement l'objet culture à cette fonction
      console.log("selection de la culture " + culture.text);
      this.$refs["modify-culture-modal"].show();

      this.cultureFormDisplay = true;
      this.stepCulturesList = [];
      this.activatedCulture = culture.text;
      this.id_culture = culture.data.id;
      this.name = culture.data.name;
      this.picture = asset(culture.data.picture);
      this.comment =
        culture.data.comment; /* 
      if (culture.children.length == 0) {
        this.columnActivityModeDisabled = true
        this.columnActiviyMessage = "La culture \"" + culture.text + "\" ne possède pas d'étapes de cultures"
        this.fetchingActivityInformations = false
      } else { */
      this.currentCulture = culture.data;
      //etapes de culture de la culture sélectionnée
      const stepscultures = this.allCultureData.find(
        item => item.id == this.currentCulture.id
      ).cultureSteps;
      /* this.treeDataStepsCultures = stepscultures.map(item => {
        return {
          text: item.name,
          data: {
            name: item.name,
            isOwn: item.isOwn,
            id: item.id,
            code: 'STEP_CULTURE'
          }
        }
      }) */
      this.stepCulturesList = stepscultures;
      //this.$refs.stepsCultureTree.setModel(this.treeDataStepsCultures)
      this.cultureIndex = 1;
    },

    // TODO: decommentaer lorrrrrrsque le bouton modifier sera totalement fonctionnel
    handleNodeSelection(node) {
      console.log("node", node);
      const code = node.data.code;
      if (code == "CULTURE") {
        this.cultureFormDisplay = true;
        this.stepFormDisplay = false;
        this.stepCulturesList = [];
        this.activityImportFormDisplay = false;
        this.activatedCulture = "de " + node.text;
        this.id_culture = node.data.id;
        this.name = node.data.name;
        this.picture = asset(node.data.picture);
        this.comment = node.data.comment;
        if (node.children.length == 0) {
          this.columnActivityModeDisabled = true;
          this.columnActiviyMessage =
            'La culture "' +
            node.text +
            "\" ne possède pas d'étapes de cultures";
          this.fetchingActivityInformations = false;
        } else {
          this.currentCulture = node.data;
          //etapes de culture de la culture sélectionnée
          const stepscultures = this.allCultureData.find(
            item => item.id == this.currentCulture.id
          ).cultureSteps;
          this.treeDataStepsCultures = stepscultures.map(item => {
            return {
              text: item.name,
              data: {
                name: item.name,
                isOwn: item.isOwn,
                id: item.id,
                code: "STEP_CULTURE"
              }
            };
          });
          this.stepCulturesList = stepscultures;
          this.$refs.stepsCultureTree.setModel(this.treeDataStepsCultures);
          this.cultureIndex = 1;
        }
      } else if (code == "STEP_CULTURE") {
        this.stepFormDisplay = true;
        this.stepExploitationId = isNaN(
          parseInt({ toString: () => node.data.id_exploitation })
        );
        if (this.stepExploitationId) {
          this.stepDeleteBtnDisplay = !this.stepDeleteBtnDisplay;
        }
        this.activityImportFormDisplay = false;
        this.id_step = node.data.id;
        this.stepName = node.data.name;
        this.stepComment = node.data.comment;
        this.activatedStepCulture = node.data.name;
        this.currentStep = node.data;
        this.currentCulture = node.parent.data;
        if (node.data.childrenLength == 0) {
          this.columnActivityModeDisabled = true;
          this.columnActiviyMessage =
            "L'étape de culture " + node.text + " ne possède pas d'activités";
          this.cultureIndex = 2;
        } else {
          this.columnActivityModeDisabled = false;
          const activities = this.allCultureData
            .find(item => item.id == this.currentCulture.id)
            .cultureSteps.find(item => item.id == this.currentStep.id)
            .activities;
          this.treeDataActivities = activities.map(item => {
            return {
              text: item.name,
              data: {
                name: item.name,
                isOwn: item.isOwn,
                id: item.id,
                id_exploitation: item.exploitation_id,
                code: "ACTIVITY"
              }
            };
          });
          this.activitiesList = activities;
          this.$refs.tree.setModel(this.treeDataActivities);
          this.cultureIndex = 2;
          this.fetchingActivityInformations = false;
        }
      } else {
        this.activityExploitationId = isNaN(
          parseInt({ toString: () => node.data.id_exploitation })
        );
        if (this.activityExploitationId) {
          this.activityDeleteBtnDisplay = !this.activityDeleteBtnDisplay;
        }
        this.activityImportFormDisplay = true;
        this.currentActivity = node.data;
        const otherInformations = this.allCultureData
          .find(item => item.id == this.currentCulture.id)
          .cultureSteps.find(item => item.id == this.currentStep.id)
          .activities.find(item => item.id == this.currentActivity.id);
        this.fetchingActivityInformations = true;
        this.activityDataDuration = otherInformations.duration;
        this.activityDataCost = otherInformations.cost;
        this.activityDataPersonNumber = otherInformations.personNumber;
        this.activityDataInputs = otherInformations.inputs;
        this.activityDataEquipments = otherInformations.equipments;
        this.activityName = otherInformations.name;
        this.activityComment = otherInformations.comment;
        this.activityDuration = otherInformations.duration;
        this.activityCost = otherInformations.cost;
        this.activityPersonNumber = otherInformations.personNumber;
        this.inputs = otherInformations.inputs;
        this.equipments = otherInformations.equipments;
      }
    },

    callResume() {
      this.fetchingAllCultures = true;
      Api.get("/exploitation/culture/resume", {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === "success" && res.data.data) {
            this.allCultureData = res.data.data.sort((a, b) => {
              if (a.isOWn) return -1;
              else if (b.isOwn) return 1;
              else return 0;
            });
          } else {
            this.error = res.data.error;
          }
        })
        .catch(error => {
          this.error = {
            message: "Echec de la connexion au serveur"
          };
        })
        .then(() => {
          this.fetchingAllCultures = false;
        });
    },
    nextComposantToVisit() {
      this.$router.push(
        "/exploitation/" + this.exploitationId + "/production/create"
      );
    }
  }
};
</script>

<style scoped>
#cultures_steps_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#cultures_steps_container>div>li {
  display: grid;
  grid-template-columns: 1fr max-content;
}

#cultures_steps_container>div>li>a {
  cursor: pointer;
}

#add-culture-container {
  display: grid;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
}

#add-culture-container li {
  cursor: pointer;
}

.tree-node.has-child.expanded.selected.draggable {
  background-color: blue !important;
}

.node_block {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr min-content;
}

.ai_badge {
  border-radius: 5px;
  width: 17px;
  height: 17px;
}

/* ai_bagde_num ici num est associé à l'etat de la culture */
.ai_badge_3 {
  background-color: #ffc107;
}

.ai_badge_4 {
  background-color: #4dbd74;
}

.ai_badge_5 {
  background-color: #dc3545;
}

.ai_badge>g {
  fill: #fff;
}

#OpenChatButton {
  position: fixed;
  right: 40px;
  bottom: 15px;
  border-radius: 50px;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 6px 24px 0 rgba(7, 94, 84, 0.24);
}

#rightSide {
  background-color: #fff;
  height: 100%;
  overflow: auto;
  overflow: hidden;
}

#rightSideContainer {
  height: 100%;
  box-sizing: border-box;
  display: grid;
  max-height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
}

#save_geneatedCulture_button {
  justify-self: center;
  margin: 20px auto;
}

.culture_chat_container {
  /* it should be always at the top */
  z-index: 1021;
  position: fixed;
  height: calc(100vh - 55px);
  bottom: 0px;
  left: 0px;
  width: 100vw;
  display: grid;
  grid-template-columns: 350px 1fr;
}

.form-control.is-invalid {
  background-image: none !important;
}

#culture-img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.autocomplete {
  width: 100%;
  border-radius: 0;
}

.num-badge {
  width: 15px;
  height: 15px;
  margin-left: -3px;
  text-align: center;
  padding: auto 0;
}

.node-number {
  margin-right: 0;
}

.tree-card {
  height: 350px !important;
}

.tree-card>div {
  overflow-y: auto;
  height: 100%;
}

.tree-card>div:nth-child(2) {
  padding: 5px;
  border-right: 1px solid #c8ced3;
}

.tree-card-input {
  padding: 10px 25px 10px 15px;
}

.tree-part {
  padding: 10px 25px;
  border-right: 1px solid #c8ced3;
}

.form-control:disabled {
  background-color: #888888 !important;
}

other-title {
  font-size: 15px;
  text-decoration: underline;
  font-family: "Verdana Pro Cond";
  font-weight: 500;
}

.not-selected {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 17px;
  color: grey;
  font-family: "Segoe UI Historic";
}

.default-unit {
  margin-top: -10px;
}

default-unit {
  font-size: 20px;
  font-family: Candara;
  font-weight: bold;
  text-decoration: underline;
}

#tree_container {
  height: 100%;
  overflow: visible;
}
</style>

<style>
/* styyle global */
.tree-node,
.tree-anchor {
  margin-left: 0 !important;
  padding: 0 !important;
}

.activity_list {
  display: grid;
  grid-template-columns: min-content 1fr !important;
  gap: 10px;
}

* {
  box-sizing: border-box;
}
</style>
